

















































import { Toast } from '@/mixins'
import { Component, Mixins } from 'vue-property-decorator'
import { Admin } from '@/api'

@Component
export default class BulkUserBan extends Mixins(Toast) {
  ids = ''
  reason = ''
  showError = false

  resetData() {
    this.ids = ''
    this.reason = ''
  }

  async bulkBanUsers() {
    try {
      const ids = this.ids.split('\n').filter(Boolean)
      await Admin.bulkBanUsers(ids, this.reason)

      this.showSuccessToast({
        title: 'Successfully banned!',
        message: `All users have been banned`
      })
      this.resetData()
    } catch(e) {
      this.showError = true

      setTimeout(() => this.showError = false, 3000)
    }
  }
}
